import React from 'react';

class TypeWriter extends React.Component {
    intervalID = null;

    constructor(props) {
      super(props)
      const txt = this.props.txtFromParent;
      this.state = {
          i: 0,
        txt: txt,
        currentText: '',
        speed: 100
      }
      this.typeWriter = this.typeWriter.bind(this);
    }
  
    typeWriter() {
      //if i=0 less than length=13 (, Click here!)
      //then set the state to currentText:'' + the silibul the index of i is in txt
      //then add plus 1 to i to loop trough the txt
        if (this.state.i < this.state.txt.length) {
          this.setState({
            currentText: this.state.currentText + this.state.txt.charAt(this.state.i),
          i: this.state.i + 1
        });
        //how fast should it be typed
          setTimeout(this.typeWriter, this.state.speed);
        }
    }


    componentDidMount() {
   
      //set an interval of 9000 to start after the hole dom is loaded.
      this.intervalID = setInterval(() => {
 
        this.typeWriter();
        //the intervalID const is 1 now, clear it and set it to null
        clearInterval(this.intervalID);

        this.intervalID = null;
    

      }, 1000);
 
  
    }



    render() {
      return (
          <span>
            {this.state.currentText}
          </span>
      )
    }
}
  
  export default TypeWriter;
  