import React, { useRef, useEffect } from 'react';
import gsap, { TweenMax, Power3, Power4 } from 'gsap';
import { useIntersection } from 'react-use';

//css and image
import logo from '../pictures/milicas-logo.svg';
import '../css/App.css';
import cvPdf from '../document/MilicaSpasenovicResume.pdf';

//icons
import { FaBitbucket, FaLinkedin, FaYoutube, FaGithub } from 'react-icons/fa';
import { FiAtSign } from 'react-icons/fi';
import { GrDocumentText } from 'react-icons/gr';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

//components
import TypeWriter from './TypeWriter.js';
import SmoothScrolling from './smoothScrolling.js';






function App()
{

  let logoItem = useRef(null);

  let sectionRef = useRef(null);

  const intersection = useIntersection(sectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: .5
  });

  const fadeIn = element =>
  {
    gsap.to(element, 1, {
      opacity: 1,
      y: -30,
      ease: Power4.easeIn,
      stagger: {
        amount: 0.3
      }
    });
  };

  const fadeOut = element =>
  {
    gsap.to(element, 1, {
      opacity: 0,
      y: -20,
      ease: Power4.easeIn,
      stagger: {
        amount: 0.4
      }
    });
  };

  intersection && intersection.intersectionRatio < 0.5
    ? fadeOut(".FadeIn-Element")
    : fadeIn(".FadeIn-Element");


  const ScrollComponent = (e) => (
    SmoothScrolling.scrollTo(e)
  );


  useEffect(() =>
  {

    TweenMax.to(
      logoItem,
      3,
      {
        opacity: 1,
        //duration: 2.5,
        ease: Power3.easeIn
      }
    )
  }, []);



  return (
    <div className="App">
      <header className="App-header" id="App-header-ID">

        <img
          ref={el => { logoItem = el }}
          src={logo} className="App-logo" alt="logo" />

        <div className="Links">
          <a
            className="App-link"
            href="https://github.com/misp15"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub />
          </a>
          <a
            className="App-link"
            href="https://bitbucket.org/MillieS/workspace/projects/PROJ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaBitbucket />
          </a>
          <a
            className="App-link"
            href="https://www.linkedin.com/in/milica-spasenovic/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
          <a
            className="App-link"
            href="mailto:spasenovic.milica@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiAtSign />
          </a>
          <a
            className="App-link"
            href="https://www.youtube.com/channel/UCJUGLQurnktrSY3gOXfD5OQ?view_as=subscriber"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube />
          </a>
          <a
            className="App-link Svg-path"
            href={cvPdf}
            download="MilicaSpasenovicResume"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GrDocumentText />
          </a>
        </div>
        <p style={{ height: '2vh' }}>
          <TypeWriter txtFromParent={'WEB DEVELOPER MILICA.S'} />
        </p>
        <button className="move-top" onClick={() => ScrollComponent('AboutBlock-ID-1')}>
          <IoIosArrowDown />
        </button>

      </header>
      <div className="Custom-Parallax CoverScreen" id="AboutBlock-ID-1" ref={sectionRef}  >
        <div className="AboutBlock-1 FadeIn-Element" >
          <h2 className="FadeIn-Element">PURPOSE
                </h2>
          <p className="FadeIn-Element">
            My purpose is to deliver
                <span> professionalism </span>,
                <span> effectiveness </span> and a
                <span> balanced </span> way of working, which promotes a
                <span> stable </span> and
                <span> successful </span> end product. Also benefits and motivates
                my peers to do better. <br />
            <span> Happy </span>programmers,
                <span> good </span>code!
                </p>
          <button className="move-top" onClick={() => ScrollComponent('AboutBlock-ID-2')}>
            <IoIosArrowDown />
          </button>
        </div>
      </div>

      <div className="AboutBlock-2 CoverScreen" id="AboutBlock-ID-2">
        <div className="AboutBlock-2-inner">

          <h2>SKILLS</h2>
          <p>Im a master of solving and finding bugs.
          A Creative and detail oriented mind. Html,
          CSS3, vanilla javascript, PHP, Ajax, Git and
                MySQL is my foundation of skills.</p>

          <p>I see myself as an aspiring fullstack developer.
          Over the years I have deep dived into Magento 1&2,
                Google Analytics and digital marketing.</p>

          <p>Environments I been working in are Ubuntu,
          Google Cloud, Bitbucket, Confluence, Jira,
          Jenkins, Netlify, Docker, Vagrant, Virtualbox
                and Kanban.</p>
          <p>In my future I see React.js, node.js,
          graphQL, headless, PWA, Singe page applications, Micro Services and .NET as my new endeavors.
                </p>
          <p>Click <a
            href={cvPdf}
            download="MilicaSpasenovicResume"
            target="_blank"
            rel="noopener noreferrer"
          >here</a> to view more about me…</p>
          <button className="move-top" onClick={() => ScrollComponent('AboutBlock-ID-3')}>
            <IoIosArrowDown />
          </button>

        </div>
      </div>

      <div className="Custom-Parallax CoverScreen" id="AboutBlock-ID-3">
        <div className="AboutBlock-3" >
          <button className="move-top" onClick={() => ScrollComponent('App-header-ID')}>
            <IoIosArrowUp />
          </button>
          <div className="Round Pic-1"></div>
          <p>Everybody is a Genius. <br />
                    But If You Judge<br /> a Fish by Its Ability to Climb a Tree,<br />
                    It Will Live Its Whole Life <br />Believing that It is Stupid.</p>
          <p>Albert Einstein</p>

        </div>
      </div>
    </div>

  );
}

export default App;
