

  var SmoothScrolling = {

    timer: null,
  
    stop: function() {
      clearTimeout(this.timer);
    },
  
    scrollTo: function(id, callback) {

      var settings = {
        duration: 1000,
        easing: {
          outQuint: function(x, t, b, c, d) {
            return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
          }
        }
      };

      var percentage;
      var startTime;

      //find the id to where you want to scroll to
      var node = document.getElementById(id);  

      //nodeTop is the number of pixels from the top 
      //of the closest relatively positioned parent element.736px
      var nodeTop = node.offsetTop;

      //nodeHeight returns the height of an element, 
      //including vertical padding and borders, as an integer.777px
      var nodeHeight = node.offsetHeight;

      //returns body
      var body = document.body;

      //returns all the tag elements 
      var html = document.documentElement;

      //height returns the heighest value
      // body.scrollHeight, 3068 ENTIRE content & padding (visible or not) of body
      // body.offsetHeight, 736
      // html.clientHeight, 736  VISIBLE content & padding
      // html.scrollHeight, 3068 ENTIRE content & padding (visible or not) of elements
      // html.offsetHeight  736
      //  offsetHeight: is a measurement in pixels
      //  of the element’s CSS height, including border, 
      //  padding and the element’s horizontal scrollbar 
      //  (if present, if rendered). It does not include 
      //  the height of pseudo-elements such as ::before or ::after.
      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      // returns the hole window 736
      var windowHeight = window.innerHeight;

      //returns ex 986  how mych has been scrolled down
      var offset = window.pageYOffset;

      var delta = nodeTop - offset;//736px - 986px
      var bottomScrollableY = height - windowHeight;//3068px-736px

      var targetY =
        bottomScrollableY < delta
          ? bottomScrollableY - (height - nodeTop - nodeHeight + offset)
          : delta;
  
      startTime = Date.now();//1597233069194

      percentage = 0;
  
      if (this.timer) {
        clearInterval(this.timer);
      }
  
      function step() {

        var yScroll;
        var elapsed = Date.now() - startTime;//1597234006597-1597233069194=937403
        
        //937403 > 1000
        if (elapsed > settings.duration) {
          clearTimeout(this.timer);
        }
  
        //937403 / 1000
        percentage = elapsed / settings.duration;
    
          if (percentage > 1) {
              
              clearTimeout(this.timer);
    
              if (callback) {
                //If a function is passed as callback parameter
                //from scrollTo function (line: 11) then
                //instanciate that function
                callback();
              }

          } else {
              
            // outQuint: function(0, 937403, 986, 250, 1000) {
            //return 250 * ((937403 = 937403 / 1000 - 1) * 937403 * 937403 * 937403 * 937403 + 1) + 986;
            //= 1.807624260104477e+29
              yScroll = settings.easing.outQuint(
                0,
                elapsed,
                offset,
                targetY,
                settings.duration
              );

              //scroll to koodinats on page, scrollTo() not supported in Safari IoS
              window.scrollTo(0, yScroll);
              this.timer = setTimeout(step, 10);
          }
      }//function step() end
  
      this.timer = setTimeout(step, 10);
    }//scrollTo function ends
  };// smoothScrolling ends
  
  export default SmoothScrolling;
  